import React from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/products.css"


import img1 from '../images/jacuzzi-ogrodowe-210-x-193/0005.webp';
import img2 from '../images/jacuzzi-ogrodowe-210-x-193/0001.webp';
import img3 from '../images/jacuzzi-ogrodowe-210-x-193/0002.webp';
import img4 from '../images/jacuzzi-ogrodowe-210-x-193/0003.webp';
import img5 from '../images/jacuzzi-ogrodowe-210-x-193/0004.webp';


import raty from '../images/raty-banner.jpg'

import ImageGallery from 'react-image-gallery';
 
const images = [
  {
    original: img1,
    thumbnail: img1,
  },
  {
    original: img2,
    thumbnail: img2,
  },
  {
    original: img3,
    thumbnail: img3,
  },
  {
    original: img4,
    thumbnail: img4,
  },
  {
    original: img5,
    thumbnail: img5,
  },
];

const ProductView14 = () => {

  const price = 13999;

  return(
  <Layout>
    <SEO 
      title="Jacuzzi" 
      description = { SEO.description }
      urlName="jacuzzi-ogrodowe-210-cm-x-193-cm"
    />
    <div className="banner">
        <div className="bannerPlate">
          <p>Jacuzzi</p>
        </div>
    </div>
    <div className="productViewPlate">

      <div className="gallery">
        <ImageGallery slideInterval="2000" items={images} />
      </div>
      <div className="productDescription">
          <div id="prodTop"></div>
          <div id="prodLeft"></div>
          <div id="prodBottom"></div>
          <div id="prodRight"></div>
          <div id="productTitle">Jacuzzi ogrodowe 210cm x 193 cm</div>
          <div id="productPrice">{price}zł
          </div>
          <div className="productOption">
              <div className="productDescriptionText">
                  <h3>Opis</h3>
                  <p>
                  Kwadratowe jacuzzi ogrodowe o wymiarach 210cm na 193cm to doskonały sposób na relaks w każdej porze roku. Posiada pompę z termostatem o mocy 3KW! Dzięki swoim rozmiarom spokojnie pomieści aż 6 osób!. Jacuzzi posiada regulację temperatury oraz podświetlenie LED.<br/>
                  </p>
                  <h3>Dane techniczne:</h3>
                  <ul>
                    <li>Podświetlenie LED</li>
                    <li>Termo pokrywa w dużym stopniu izolująca ciepło</li>
                    <li>Grzałka elektryczna z termostatem 3KW</li>
                    <li>Funkcja AIR</li>
                    <li>Pompa</li>
                    <li>Metalowy stelaż</li>
                    <li>Regulacja temperatury(15-60 stopni)</li>
                    <li>12 dysz powietrznych</li>
                    <li>Obicie drewnem na zewnątrz</li>
                    <li>Schodki</li>
                  </ul>
                  <a href="https://epos.inbank.pl/pl/drewhaus">
                  <img src={raty} className="creditBanner" alt="domek-ogrodowy-raty"/>
                  </a>
                  <p><b>Jacuzzi ogrodowe możesz obejrzeć w Gnieźnie! Zajrzyj do zakładki&nbsp; 
                    <Link to="/contact" style={{ color: '#765F52' }} >
                      kontakt
                    </Link> i sprawdź gdzie nas znajdziesz!</b></p>

              </div>
          </div>
      </div>
    </div>
  </Layout>
  )}

export default ProductView14


